<!-- eslint-disable vue/no-v-html -->
<template>
	<v-flex
		color="primary"
		class="drop-zone pointer"
		align-center
		justify-center
		text-xs-center
		caption
		:pa-5="!customSize"
		@click="onClick"
		@dragover.prevent
		@drop="onDrop"
		v-html="text"
	>
	</v-flex>
</template>

<script>
import { Bus, Events } from '@/events/AppEvents'

/**
 * @displayName DropZone
 */
export default {
	name: 'DropZone',
	props: {
		acceptedMimeTypes: {
			required: false,
			type: String,
			default: null
		},
		text: {
			required: false,
			type: String,
			default: function () {
				return this.$t('drop-zone')
			}
		},
		multiple: {
			required: false,
			type: Boolean,
			default: false
		},
		value: {
			required: false,
			type: [Array, File, undefined],
			default: undefined
		},
		customSize: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			uid: Date.now().toString() + '-' + Math.floor(Math.random() * 100).toString()
		}
	},
	methods: {
		addFiles: function (files) {
			let currentValue = null
			if (this.multiple) {
				currentValue = [...this.value]
				Array.from(files).forEach(file => {
					currentValue.push(file)
				})
			} else {
				currentValue = files[0]
			}
			this.emitInput(currentValue)
		},
		emitInput: function (data) {
			/**
			 * @event success
			 *
			 * @property {Array} files
			 */
			this.$emit('input', data)
		},
		getFileFromFileEntry: function (fileEntry) {
			return new Promise(res => {
				fileEntry.file(file => {
					res(file)
				})
			})
		},
		getFilesOfDirectory: async function (directory) {
			let result = []
			let directoryReader = directory.createReader()
			result = await new Promise(res => {
				directoryReader.readEntries(async entries => {
					let files = []
					for (let i = 0; i < entries.length; i++) {
						const entry = entries[i]
						if (entry.isFile) {
							const file = await this.getFileFromFileEntry(entry)
							files.push(file)
						} else if (entry.isDirectory) {
							const filesToAdd = await this.getFilesOfDirectory(entry)
							files = files.concat(filesToAdd)
						}
					}
					res(files)
				})
			})
			return result
		},
		onClick: function () {
			const fileInput = document.createElement('input')
			if (this.acceptedMimeTypes) {
				fileInput.accept = this.acceptedMimeTypes
			}
			fileInput.multiple = true
			fileInput.type = 'file'
			fileInput.addEventListener(
				'input',
				function (e) {
					e.preventDefault()
					const files = e.target.files
					this.addFiles(files)
					fileInput.remove()
				}.bind(this)
			)
			fileInput.click()
		},
		onDrop: async function (event) {
			event.stopPropagation()
			event.preventDefault()

			const dataTransfer = event.dataTransfer

			let newFiles = []
			if (dataTransfer.items) {
				for (let i = 0; i < dataTransfer.items.length; i++) {
					const entry = dataTransfer.items[i].webkitGetAsEntry()
					if (entry.isDirectory) {
						let files = await this.getFilesOfDirectory(entry)
						newFiles = newFiles.concat(files)
					} else {
						newFiles.push(dataTransfer.items[i].getAsFile())
					}
				}
			} else if (dataTransfer.files) {
				dataTransfer.files.forEach(file => {
					newFiles.push(file)
				})
			} else {
				throw new Error("Browser doesn't support OS file Drag n' Drop")
			}
			if (this.acceptedMimeTypes) {
				const authorizedFiles = newFiles.filter(file => {
					return file.type && this.acceptedMimeTypes.includes(file.type)
				})

				if (authorizedFiles.length == 0) {
					Bus.emit(Events.SNACKBAR_ERROR, this.$tc('errors.drop_zone.unauthorized_file_type', newFiles.length))
				}

				newFiles = authorizedFiles
			}
			this.addFiles(newFiles)
		}
	}
}
</script>

<style>
.drop-zone {
	border: 0.15em dashed;
}
</style>
